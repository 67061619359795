import React from 'react';

import AcquisitionCompsCard from './cards/acquisitionCompsCard';
import AroEconomicsCard from './cards/aroEconomicsCard';
import CharacteristicsCard from './cards/characteristicsCard';
import MapCard from './cards/mapCard';
import ProductFitCard from './cards/productFitCard';
import SaleCompsCard from './cards/saleCompsCard';
import SourceAndStatusCard from './cards/sourceAndStatusCard';
import ViabilityCard from './cards/viabilityCard';
import { canDisplayCard } from '../../utilities/scoutHelpers';

import './scoutDashboard.css';
import { useAppSelector } from '../../store/hooks';
import { selectUserProfile } from '../../store/slices/authSlice';

// Helper Components for Cards
export const Dashboard_Not_Available_Label = 'not available';
export const DashboardCardTitle = ({ text }) => (
    <h3 className="fs-5 scoutDashboardCardTitle mb-2">{text}</h3>
);

export const convertValueForDashboardDisplay = (
    originalStringValue,
    mathFunc,
    prefix,
    suffix,
    zeroOK = true
) => {
    if (!originalStringValue) {
        return Dashboard_Not_Available_Label;
    }
    let number = Number(originalStringValue);
    if (mathFunc) {
        number = mathFunc(number);
    }
    if (!zeroOK && number === 0) {
        return Dashboard_Not_Available_Label;
    }
    return prefix + number.toLocaleString() + suffix;
};

// Grid Layout Helper Components
const DashboardRow = ({ children }) => (
    <div className="row g-lg-2 scoutDashboardRow">{children}</div>
);

const DashboardCardContainer = ({ bsColumns, children }) => {
    const widthClass = `col-lg-${bsColumns} col-12 mb-2 mb-lg-0`;
    // Note: card background can't be set here because BS implements gutters as padding (vs gap)
    return <div className={`${widthClass} ps-2 pe-0`}>{children}</div>;
};

const DashboardCard = ({ children }) => (
    <div className="h-100 rounded py-2 px-3 scoutDashboardCard">{children}</div>
);

const dashboardLayout = [
    [
        { Component: MapCard, useCardWrapper: false, columns: 4, name: 'MapCard' },
        {
            Component: ProductFitCard,
            useCardWrapper: true,
            columns: 3,
            name: 'ProductFitCard',
        },
        {
            Component: AcquisitionCompsCard,
            useCardWrapper: true,
            columns: 5,
            name: 'AcquisitionCompsCard',
        },
    ],
    [
        {
            Component: AroEconomicsCard,
            useCardWrapper: true,
            columns: 4,
            name: 'AroEconomicsCard',
        },
        {
            Component: CharacteristicsCard,
            useCardWrapper: true,
            columns: 3,
            name: 'CharacteristicsCard',
        },
        {
            Component: SaleCompsCard,
            useCardWrapper: true,
            columns: 5,
            name: 'SaleCompsCard',
        },
    ],
    [
        {
            Component: SourceAndStatusCard,
            useCardWrapper: true,
            columns: 4,
            name: 'SourceAndStatusCard',
        },
        {
            Component: ViabilityCard,
            useCardWrapper: true,
            columns: 8,
            name: 'ViabilityCard',
        },
    ],
];

function ScoutDashboard({ property, onScoutPropertyUpdate }) {
    const userProfile = useAppSelector(selectUserProfile);

    return (
        <>
            {dashboardLayout.map((row, index) => (
                <DashboardRow key={`row-${index}`}>
                    {row.map((card, cardIndex) => {
                        const { Component, columns, useCardWrapper } = card;
                        return (
                            <DashboardCardContainer
                                key={`card-${index}-${cardIndex}`}
                                bsColumns={columns}
                            >
                                {!canDisplayCard(
                                    card.name,
                                    userProfile
                                ) ? null : useCardWrapper ? (
                                        <DashboardCard>
                                            <Component
                                                property={property}
                                                onUpdate={onScoutPropertyUpdate}
                                            />
                                        </DashboardCard>
                                    ) : (
                                        <Component
                                            property={property}
                                            onUpdate={onScoutPropertyUpdate}
                                        />
                                    )}
                            </DashboardCardContainer>
                        );
                    })}
                </DashboardRow>
            ))}
        </>
    );
}

export default ScoutDashboard;
