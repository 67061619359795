// RequireAuth.js
import React, {useEffect, useState} from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import Loader from './loader/loader';
import { useAppDispatch} from '../store/hooks';
import { setToken, setUserEmail, setUserProfile } from '../store/slices/authSlice';
import { setAmplifyConfigration } from './library/amplifyConfig';

export function RequireAuth({ children }) {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [tokenStored, setTokenStored] = useState(false);
    const { user, route } = useAuthenticator((context) =>[context.user, context.route]);
    
    useEffect(() => {
        async function authenticateAndStoreToken() {
            let data = await Auth.currentSession();
            let idToken = data.getIdToken();
            localStorage.setItem('id_token', idToken);
            localStorage.setItem('jwt_token', idToken.jwtToken);
            dispatch(setToken({...idToken}));
            dispatch(setUserEmail(idToken.payload.email));
            setTokenStored(true);
        }
        setAmplifyConfigration();
        if (route === 'authenticated') {
            authenticateAndStoreToken();
        }
        else {
            dispatch(setToken(null));
        }

    }, [route]);

    useEffect(() => {
        dispatch(setUserProfile(user?.attributes?.profile || ''));
    }, [user]);

    if (route !== 'authenticated') {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    else if (!tokenStored) {
        return <Loader />;
    }
    return children;
}
