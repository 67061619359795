import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import Map from './componets/map/map';
import Kanban from './componets/kanban/kanban';
import Scout from './componets/scout/scout';
import { RequireAuth } from './componets/RequireAuth';
import { Login } from './componets/login';
import TermsOfUse from './componets/termsOfUse';
import { store } from './store/store';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import '@aws-amplify/ui-react/styles.css';

Auth.configure({
    Auth: {
    // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-1_TFh8c2imx',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '3o5a4vta1g3ibh2see9kh6nm14',
    },
});

function MyRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    index
                    element={
                        <RequireAuth>
                            <Map path={'/'} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/kanban"
                    index
                    element={
                        <RequireAuth>
                            <Kanban path={'/kanban'} />
                        </RequireAuth>
                    }
                />
                <Route
                    exact
                    path="/property/:id"
                    element={
                        <RequireAuth>
                            <Scout path="/property/:id" />
                        </RequireAuth>
                    }
                />
                <Route path="/terms" element={<TermsOfUse />} />
                <Route path="/login" element={<Login />} />
            </Routes>
        </BrowserRouter>
    );
}

function App() {
    return (
        <Authenticator.Provider>
            <Provider store={store}>
                <MyRoutes />
            </Provider>
        </Authenticator.Provider>
    );
}

export default App;
