import React from 'react';
import './termsOfUse.css';

const TermsOfUseText = () => (
    <>
        <h1 className="mb-3">Terms of Use</h1>
        <p>
      Welcome to the Aro Homes Property Intelligence website (the
      &quot;Website&quot;), owned and operated by Vesta Housing Inc., DBA Aro
      Homes (&quot;Aro Homes,&quot; &quot;we,&quot; &quot;our,&quot; or
      &quot;us&quot;). By accessing or using this Website, you agree to the
      following Terms of Use. If you do not agree, you must not access or use
      the Website.
        </p>

        <hr />
        <ol>
            <li className="termsOfUseListItem">
                <span>Invitation-Only Access</span>
                <p className="termsOfUseListItemDetails">
          Access to this Website is by invitation only. Aro Homes reserves the
          right to revoke an invitation and terminate access at any time and for
          any reason, at its sole discretion.
                </p>
                <hr />
            </li>

            <li className="termsOfUseListItem">
                <span>Prohibited Actions </span>
                <p className="termsOfUseListItemDetails">
          Users of this Website must not:
                    <br />
          - Share pictures, screenshots, or any other visual representation of
          the Website or its contents, including but not limited to social
          media, conferences, or any other public or private forum.
                    <br />
          - Scrape, download, or otherwise extract data from the Website.
                    <br />- Use the Website for any unlawful or unauthorized purposes.
                </p>
                <hr />
            </li>

            <li className="termsOfUseListItem">
                <span>Reporting Issues</span>
                <p className="termsOfUseListItemDetails">
          Users must report any bugs, errors, or other technical issues
          encountered on the Website to Aro Homes at{' '}
                    <span className="fw-bold">patrick@aro.homes</span>.
                </p>
                <hr />
            </li>

            <li className="termsOfUseListItem">
                <span>Ownership & Intellectual Property</span>
                <p className="termsOfUseListItemDetails">
          All content on the Website, including but not limited to text, images,
          data, and any data entered into the Website by users, is the exclusive
          property of Aro Homes. Users do not have any ownership rights to any
          content on the Website.
                </p>
                <hr />
            </li>

            <li className="termsOfUseListItem">
                <span>Disclaimer of Liability</span>
                <p className="termsOfUseListItemDetails">
          The data and information provided on the Website may contain errors.
          Users should consult with a licensed engineer before making any design
          or construction decisions. Aro Homes assumes no responsibility or
          liability for any actions taken based on the content provided on the
          Website.
                </p>
                <hr />
            </li>

            <li className="termsOfUseListItem">
                <span>Modification of Terms</span>
                <p className="termsOfUseListItemDetails">
          Aro Homes reserves the right to modify these Terms of Use at any time.
          Continued use of the Website after changes have been made constitutes
          acceptance of the revised Terms.
                </p>
                <hr />
            </li>

            <li className="termsOfUseListItem">
                <span>Termination</span>
                <p className="termsOfUseListItemDetails">
          Aro Homes may terminate a userís access to the Website for any
          violation of these Terms of Use. Additionally, Aro Homes reserves the
          right to terminate access at any time, at its sole discretion, without
          notice or liability.
                </p>
                <hr />
            </li>

            <li className="termsOfUseListItem">
                <span>Governing Law</span>
                <p className="termsOfUseListItemDetails">
          These Terms of Use shall be governed by and construed in accordance
          with the laws of the State of California, USA, without regard to its
          conflict of law principles.
                </p>
                <hr />
            </li>
        </ol>

        <p>
      By accessing or using the Website, you acknowledge that you have read,
      understood, and agreed to these Terms of Use.
        </p>
    </>
);

export default TermsOfUseText;
