import React, { useMemo, useState } from 'react';

import ScoutDashboard from '../scoutDashboard';
import ScoutViability from '../scoutViability';
import ScoutComparables from '../scoutComparables';
import ScoutBuyBox from '../scoutBuyBox';
import ScoutTabNavigator from './scoutTabNavigator';
import { useAppSelector } from '../../store/hooks';
import { selectUserProfile } from '../../store/slices/authSlice';
import { UserTypes } from '../../constants';

export const ScoutTabAro = {
    Dashboard: 'dashboard',
    Comparables: 'comparables',
    Viability: 'viability',
    BuyBox: 'buyBox',
};

export const ScoutTabExternal = {
    Dashboard: 'dashboard',
};

function ScoutTabs({
    property,
    onScoutPropertyUpdate,
}) {
    const userProfile = useAppSelector(selectUserProfile);
    const [selectedTab, setSelectedTab] = useState(ScoutTabAro.Dashboard);

    const selectTab = (tab) => setSelectedTab(tab);

    const tabs = useMemo(
        () => (userProfile === UserTypes.aro ? ScoutTabAro : ScoutTabExternal),
        [userProfile]
    );

    const getTabContentsComponent = () => {
        switch (selectedTab) {
        case ScoutTabAro.Dashboard:
            return (
                <ScoutDashboard
                    property={property}
                    onScoutPropertyUpdate={onScoutPropertyUpdate}
                />
            );
        case ScoutTabAro.Comparables:
            return (
                <ScoutComparables
                    property={property}
                    onScoutPropertyUpdate={onScoutPropertyUpdate}
                />
            );
        case ScoutTabAro.Viability:
            return (
                <ScoutViability
                    property={property}
                    onScoutPropertyUpdate={onScoutPropertyUpdate}
                />
            );
        case ScoutTabAro.BuyBox:
            return (
                <ScoutBuyBox
                    property={property}
                    onScoutPropertyUpdate={onScoutPropertyUpdate}
                />
            );
        }
        return null;
    };

    return (
        <>
            <div className="col-12">
                <ScoutTabNavigator
                    selected={selectedTab}
                    onSelect={selectTab}
                    tabs={tabs}
                />
            </div>
            <div className="mt-2">{getTabContentsComponent()}</div>
        </>
    );
}

export default ScoutTabs;
