import React from 'react';
import { useAppSelector } from '../../../store/hooks';
import { selectHomeProducts } from '../../../store/slices/mapSlice';
import { DashboardCardTitle, convertValueForDashboardDisplay } from '..';
import { computeFAR } from '../../../utilities/scoutHelpers';
import { colors } from '../../../constants';
import { CheckIcon, CloseIcon, QuestionIcon } from '../../../icons';

const MaxFitMessageLength = 7;

const ProductFitCard = ({ property: { propertyDetails } }) => {
    const homeProducts = useAppSelector(selectHomeProducts);
    const { PropertyGeometry, PropertyStructure, Zone, lot_analysis } =
    propertyDetails;

    const propertyWidth = convertValueForDashboardDisplay(
        PropertyGeometry.vendor_lot_width_ft,
        Math.floor,
        '',
        '',
        false
    );
    const propertyDepth = convertValueForDashboardDisplay(
        PropertyGeometry.vendor_lot_depth_ft,
        Math.floor,
        '',
        '',
        false
    );
    const propertySF = convertValueForDashboardDisplay(
        PropertyGeometry.lot_size_sq_ft,
        Math.floor,
        '',
        '',
        false
    );
    const buildingSF = convertValueForDashboardDisplay(
        PropertyStructure.building_sq_ft,
        Math.ceil,
        '',
        '',
        false
    );

    const maxValueLength = Math.min(
        9,
        Math.max(
            propertyWidth.length,
            propertySF.length,
            buildingSF.length,
            Zone.name.length,
            MaxFitMessageLength
        )
    );
    const valueTDWidth = `${maxValueLength}em`;

    const estimatedFAR = computeFAR(PropertyGeometry.lot_size_sq_ft);

    const renderRow = (label, value) => (
        <tr>
            <td>{label}</td>
            <td style={{ width: valueTDWidth }}>
                <span style={{ fontWeight: 500 }}>{value}</span>
            </td>
        </tr>
    );

    const renderFitValue = (fit) => {
        switch (fit) {
        case true:
            return (
                <>
                    <span className="productFits">FITS</span>
                    <CheckIcon color={colors.aroGreen} size="24" />
                </>
            );
        case false:
            return (
                <>
                    <span className="productNoFit">NO FIT</span>
                    <CloseIcon color={colors.aroRed} size="24" />
                </>
            );
        case null:
        default:
            return (
                <>
                    <span className="fitUnknown">UNKNOWN</span>
                    <QuestionIcon size="18" />
                </>
            );
        }
    };

    const renderLotFitsRow = (type) => {
        const lotAnalysis = lot_analysis[type];
        const fits = lotAnalysis.zoning_known ? lotAnalysis.house_does_fit : null;

        return (
            <tr key={type}>
                <td>
                    {type}&nbsp;&nbsp;{homeProducts[type]?.name || ''}
                </td>
                <td style={{ width: valueTDWidth }}>
                    <div
                        className="d-flex gap-1 align-items-center fw-bold lotFitValue"
                    >
                        {renderFitValue(fits)}
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <div>
            <DashboardCardTitle text="Product Fit" />
            <table className="w-100">
                <tbody>
                    {renderRow('Lot Width', propertyWidth)}
                    {renderRow('Lot Depth', propertyDepth)}
                    {renderRow('Lot SF', propertySF)}
                    {renderRow('Building SF', buildingSF)}
                </tbody>
            </table>
            <table className="mt-2 w-100">
                <tbody>
                    {renderRow('Zoning', Zone.name)}
                    {renderRow('Est. FAR at Sale', estimatedFAR)}
                </tbody>
            </table>
            <table className="mt-3 w-100">
                <tbody>
                    {Object.keys(homeProducts).map((product) =>
                        renderLotFitsRow(product)
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ProductFitCard;
