import React from 'react';
import TermsOfUseText from './termsOfUseText';

function TermsOfUse() {
    return (
        <div className="p-5">
            <TermsOfUseText />
        </div>
    );
}

export default TermsOfUse;
