import { configureStore } from '@reduxjs/toolkit';
import {
    createStateSyncMiddleware,
    initMessageListener,
} from 'redux-state-sync';
import authReducer from './slices/authSlice';
import { aroApiSlice } from './slices/apiSlice/apiSlice';
import mapReducer from './slices/mapSlice';

const config = {};
export const store = configureStore({
    reducer: {
        auth: authReducer,
        map: mapReducer,
        [aroApiSlice.reducerPath]: aroApiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        getDefaultMiddleware({ serializableCheck: false }).prepend(
            createStateSyncMiddleware(config)
        );
        return getDefaultMiddleware({ serializableCheck: false }).concat(
            aroApiSlice.middleware
        );
    },
});

initMessageListener(store);
