import React from 'react';
import CurrencyInput from 'react-currency-input-field';

import PassFailDropdown from './passFailDropdown';
import ProductZoning from './productZoning';
import { ViabilityFields } from '../../constants';
import './scoutViability.css';

const ViabilityFieldContainer = ({
    viabilityField,
    property,
    values,
    propertyZoning,
    onChange,
    onPropertyZoningChange
}) => {
    const { key, label, hasCostEstimate, isPassFailField } = viabilityField;

    const handleNotesChange = (ev) => {
        onChange(key, ViabilityFields.Notes, ev.target.value);
    };

    const handlePassFailChange = (selection) => {
        onChange(key, ViabilityFields.Pass, selection);
    };

    const handleCostEstimateChange = (value) => {
        onChange(key, ViabilityFields.CostEstimate, value);
    };

    const notesBorder = { borderRadius: hasCostEstimate ? '0 0 6px 6px' : '6px' };

    const renderAdditionalFields = () => {
        switch (viabilityField.key) {
        case 'ZoningJurisdiction':
            return <ProductZoning property={property} propertyZoning={propertyZoning} onChange={onPropertyZoningChange} />;
        default:
            return null;
        }
    };
    return (
        <div
            className="col-xl-3 col-lg-4 col-sm-12 col-xs-12 h-100 mb-3 d-flex flex-column"
            style={{ minHeight: '200px' }}
        >
            <div className="px-1 mb-1 d-flex justify-content-between align-items-center scoutViabilityFieldTitleContainer">
                <label
                    htmlFor={key}
                    className="form-label mb-0 scoutViabilityFieldLabel"
                >
                    {label}
                </label>
                {isPassFailField && (
                    <PassFailDropdown
                        name={key}
                        selected={values.pass}
                        onSelect={handlePassFailChange}
                    />
                )}
            </div>
            {renderAdditionalFields()}
            {hasCostEstimate && (
                <div className="w-100 bg-primary">
                    <CurrencyInput
                        value={values[ViabilityFields.CostEstimate]}
                        id={`${key}-est`}
                        name={`${key}-est`}
                        placeholder={'Cost Estimate'}
                        allowDecimals={false}
                        allowNegativeValue={false}
                        onValueChange={handleCostEstimateChange}
                        prefix="$"
                        style={{
                            border: 'none',
                            borderRadius: '6px 6px 0 0',
                            width: '100%',
                            padding: '0 6px',
                            fontSize: '14px',
                        }}
                    />
                </div>
            )}
            <textarea
                id={key}
                className="form-control h-100 shadow-sm pt-2 px-3 flex-grow-1 scoutViabilityField"
                style={notesBorder}
                value={values[ViabilityFields.Notes]}
                onChange={handleNotesChange}
            />
        </div>
    );
};

export default ViabilityFieldContainer;
