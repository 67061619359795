import React, { useState } from 'react';

import ViabilityFieldContainer from './viabilityFieldContainer';
import {
    ValidationValues,
    ValidationFields,
    ViabilityFields,
} from '../../constants';
import './scoutViability.css';

const SaveButton = ({ disabled, onSave }) => (
    <button
        className="btn btn-primary w-auto h-75 p-4 fs-4 rounded-pill scoutViabilityFieldLabel"
        disabled={disabled}
        onClick={onSave}
    >
    Save Changes
    </button>
);

const ScoutViability = ({
    property: { propertyDetails },
    onScoutPropertyUpdate,
}) => {
    const { PropertyScoutFields } = propertyDetails;
    const validation_fields = PropertyScoutFields.validation_fields || {};

    const [values, setValues] = useState(() =>
        ValidationFields.reduce((acc, fld) => {
            const notes = validation_fields[fld.key]
                ? validation_fields[fld.key][ViabilityFields.Notes] || ''
                : '';
            const costEstimate = validation_fields[fld.key]
                ? // eslint-disable-next-line no-prototype-builtins
                validation_fields[fld.key].hasOwnProperty(
                    ViabilityFields.CostEstimate
                )
                    ? validation_fields[fld.key][ViabilityFields.CostEstimate]
                    : undefined
                : undefined;
            const pass = validation_fields[fld.key]
                ? validation_fields[fld.key][ViabilityFields.Pass] ||
          ValidationValues.NA
                : ValidationValues.NA;
            return {
                ...acc,
                [fld.key]: {
                    notes,
                    costEstimate,
                    pass,
                },
            };
        }, {})
    );
    const [propertyZoning, setPropertyZoning] = useState(
        propertyDetails.PropertyZoning
    );
    const [isValuesChanged, setIsValuesChanged] = useState(false);
    const [isPropertyZoningChanged, setIsPropertyZoningChanged] = useState(false);

    const handleViabilityValuesChange = (fieldName, valueType, value) => {
        setValues((vals) => {
            const newVals = { ...vals[fieldName] };
            newVals[valueType] = value;
            return { ...vals, [fieldName]: newVals };
        });
        setIsValuesChanged(true);
    };

    const handlePropertyZoningChange = (newValues) => {
        setPropertyZoning((pz) => ({ ...pz, ...newValues }));
        setIsPropertyZoningChanged(true);
    };

    const handleSave = async (ev) => {
        ev.preventDefault();
        if (isValuesChanged) {
            let propertyScoutFields = { ...propertyDetails.PropertyScoutFields };
            propertyScoutFields.validation_fields = { ...values };
            onScoutPropertyUpdate('PropertyScoutFields', propertyScoutFields);
        }
        if (isPropertyZoningChanged) {
            onScoutPropertyUpdate('PropertyZoning', propertyZoning);
        }
    };

    return (
        <>
            <div className="row gx-sm-3 gy-0 scoutViabilityRow">
                {ValidationFields.map((field) => (
                    <ViabilityFieldContainer
                        key={field.key}
                        viabilityField={field}
                        propertyZoning={propertyZoning}
                        values={values[field.key]}
                        onChange={handleViabilityValuesChange}
                        onPropertyZoningChange={handlePropertyZoningChange}
                        property={propertyDetails}
                    />
                ))}
            </div>
            <div className="d-flex w-100 justify-content-center">
                <SaveButton
                    onSave={handleSave}
                    disabled={!(isValuesChanged || isPropertyZoningChanged)}
                />
            </div>
        </>
    );
};

export default ScoutViability;
