import { createSlice } from '@reduxjs/toolkit';

import { mapApiSlice } from './apiSlice/map-api-slice';

const initialState = {
    sources: {},
    cities: [],
    productZones: {},
    homeProducts: {},
    statuses: {},
};

export const mapSlice = createSlice({
    name: 'map',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            mapApiSlice.endpoints.getMapInitData.matchFulfilled,
            (state, action) => {
                const cities = action.payload?.valid_cities || [];
                cities.sort((a, b) => (a > b ? 1 : -1));
                state.cities = cities;
                state.statuses = action.payload?.valid_statuses || {};
                state.sources = action?.payload?.valid_sources || {};
                state.productZones = action?.payload?.valid_product_zones || {};
                state.homeProducts = action?.payload?.home_products || {};
            }
        );
    },
});

export const selectCities = (state) => state.map.cities;
export const selectSources = (state) => state.map.sources;
export const selectStatuses = (state) => state.map.statuses;
export const selectProductZones = (state) => state.map.productZones;
export const selectHomeProducts = (state) => state.map.homeProducts;
export default mapSlice.reducer;
