import React from 'react';

function ScoutTabNavigator({ tabs, selected, onSelect }) {
    const tabStyling = (tab) =>
        selected === tab ? 'text-decoration-underline' : null;

    return (
        <ul className="nav">
            {Object.keys(tabs).map((tab) => (
                <li className="nav-item" key={tab}>
                    <a
                        className={`nav-link ps-0 pe-3 pe-lg-5 pb-0 fs-6 ${tabStyling(
                            tabs[tab]
                        )}`}
                        onClick={() => onSelect(tabs[tab])}
                    >
                        {tab}
                    </a>
                </li>
            ))}
        </ul>
    );
}

export default ScoutTabNavigator;
