import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: null,
        userEmail: '',
        userProfile: '',
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setUserEmail: (state, action) => {
            state.userEmail = action.payload;
        },
        setUserProfile: (state, action ) => {
            state.userProfile = action.payload;
        },
    },
});

export const { setToken, setUserEmail, setUserProfile } = authSlice.actions;

export const selectAuthToken = (state) => state.auth.token;
export const selectUserEmail = (state) => state.auth.userEmail;
export const selectUserProfile = (state) => state.auth.userProfile;

export default authSlice.reducer;
