import React, { useEffect, useMemo, useState } from 'react';

import {
    PropertyScoutFieldNames,
    computeAvgSalePrice,
    computeAvgSFPrice,
    computeWeightedAvgSalePrice,
    updatePropertyScoutFields,
} from '../../../utilities/scoutHelpers';
import Loading from '../../loading';
import ComparablesTable from './comparablesTable';
import ComparablesMap from './comparablesMap';
import AddComparableButton from './addComparableButton';
import Pill, { PillBackgroundColors } from '../../pill';
import { colors } from '../../../constants';
import { EditIcon, ResetIcon } from '../../../icons';
import '../scoutComparables.css';

const ComparablesCard = ({
    title,
    isLoading,
    error,
    comps: propComps,
    keyId,
    property,
    onUpdate,
}) => {
    const {
        PropertyGeometry: { latitude, longitude },
    } = property;
    const [selectedComp, setSelectedComp] = useState();
    const [editActive, setEditActive] = useState(false);

    const [comps, setComps] = useState([]);
    const [shouldUpdateComps, setShouldUpdateComps] = useState(true);

    useEffect(() => {
        if (shouldUpdateComps) {
            // initial load, remove or reset
            // other operations don't update comps to avoid "flashing" old values
            setComps(propComps);
        }
    }, [propComps, shouldUpdateComps]);

    const avgSalePrice = useMemo(
        () => (comps ? computeAvgSalePrice(comps) : null),
        [comps]
    );

    const weightedAvgSalePrice = useMemo(
        () => (comps?.length ? computeWeightedAvgSalePrice(comps) : null),
        [comps]
    );

    const avgSFPrice = useMemo(
        () => (comps?.length ? computeAvgSFPrice(comps) : null),
        [comps]
    );
    const handleSelect = (comp) => setSelectedComp(comp);

    const handleEdit = () => setEditActive(true);

    const handleCancel = () => setEditActive(false);

    const renderError = () => <p>Error retrieving comps</p>;

    const saveChanges = (updatedComps) => {
        setComps(updatedComps);
        let genericData = {
            ...property.PropertyScoutFields[PropertyScoutFieldNames.GenericData],
        };
        const compsKey = `${keyId}Comps`;
        genericData[compsKey] = {
            ...genericData[compsKey],
            comps: updatedComps.map((comp) => ({
                propertyId: comp.pid,
                weight: comp.weight,
            })),
        };
        onUpdate(
            'PropertyScoutFields',
            updatePropertyScoutFields(
                property.PropertyScoutFields,
                PropertyScoutFieldNames.GenericData,
                genericData
            )
        );
    };

    const handleSave = (weights) => {
        const updatedComps = comps.map((comp) => ({
            ...comp,
            weight: weights[comp.pid],
        }));
        saveChanges(updatedComps);
        setShouldUpdateComps(false);
        setEditActive(false);
    };

    const handleRemove = (propId) => {
        let genericData = {
            ...property.PropertyScoutFields[PropertyScoutFieldNames.GenericData],
        };
        const compsKey = `${keyId}Comps`;
        const removedIds = genericData[compsKey]?.removedIds
            ? [...genericData[compsKey].removedIds]
            : [];
        removedIds.push(propId);
        genericData[compsKey] = {
            ...genericData[compsKey],
            removedIds,
        };
        onUpdate(
            'PropertyScoutFields',
            updatePropertyScoutFields(
                property.PropertyScoutFields,
                PropertyScoutFieldNames.GenericData,
                genericData
            )
        );
        setShouldUpdateComps(true);
    };

    const handleReorder = (id, source, destination) => {
        const newList = [...comps];
        const compToMove = newList.find((comp) => comp.pid === Number(id));
        if (compToMove) {
            newList.splice(source.index, 1);
            newList.splice(destination.index, 0, compToMove);
            saveChanges(newList);
            setShouldUpdateComps(false);
        }
    };

    const handleReset = () => {
        const compsKey = `${keyId}Comps`;
        const genericData = {
            ...property.PropertyScoutFields.generic_data,
        };
        genericData[compsKey] = {
            ...genericData[compsKey],
            comps: [],
            removedIds: [],
        };
        onUpdate(
            'PropertyScoutFields',
            updatePropertyScoutFields(
                property.PropertyScoutFields,
                PropertyScoutFieldNames.GenericData,
                genericData
            )
        );
        setShouldUpdateComps(true);
    };

    return (
        <div className="rounded shadow-sm py-2 px-3 mb-2 scoutComparablesCard">
            <div className="cardTitleContainer d-flex justify-content-between">
                <h2 className="fs-5 mb-2 scoutComparablesCardTitle">{title}</h2>
                {!!(comps && comps.length) && (
                    <div>
                        <AddComparableButton disabled={editActive} />
                        <button
                            className={'btn p-0 ms-2 border-0'}
                            disabled={editActive}
                            onClick={handleEdit}
                        >
                            <EditIcon color={editActive ? colors.aroGray : colors.charcoal} />
                        </button>
                        <button
                            className={'btn p-0 ms-2 border-0'}
                            disabled={editActive}
                            onClick={handleReset}
                        >
                            <ResetIcon
                                color={editActive ? colors.aroGray : colors.charcoal}
                            />
                        </button>
                    </div>
                )}
            </div>
            {isLoading ? (
                <Loading />
            ) : error ? (
                renderError()
            ) : comps && comps.length ? (
                <div className="cardContent">
                    <ComparablesTable
                        comps={comps}
                        onSelect={handleSelect}
                        keyId={keyId}
                        onReorder={handleReorder}
                        editOK={editActive}
                        onSave={handleSave}
                        onCancel={handleCancel}
                        onRemove={handleRemove}
                    />

                    <div>
                        <div className="mapContainer">
                            <ComparablesMap
                                comps={comps}
                                keyId={keyId}
                                selected={selectedComp}
                                propertyLocation={{ latitude, longitude }}
                            />
                        </div>
                        <div className="mt-1 d-flex justify-content-between">
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="compStatsPillLabel text-end me-1">
                  Avg Sale
                                    <br />
                  Price
                                </div>
                                <div className="compStatsPillContainer">
                                    <Pill
                                        label={avgSalePrice}
                                        background={PillBackgroundColors.LinenDark}
                                        compact={true}
                                    />
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="compStatsPillLabel text-end me-1">
                  Weighted
                                    <br />
                  Avg Price
                                </div>
                                <div className="compStatsPillContainer">
                                    <Pill
                                        label={weightedAvgSalePrice.formatted}
                                        background={PillBackgroundColors.LinenDark}
                                        compact={true}
                                    />
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="compStatsPillLabel text-end me-1">
                  Avg
                                    <br />
                  SF Price
                                </div>
                                <div className="compStatsPillContainer">
                                    <Pill
                                        label={avgSFPrice}
                                        background={PillBackgroundColors.LinenDark}
                                        compact={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                'No comp data'
            )}
        </div>
    );
};

export default ComparablesCard;
