import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useGetMapInitDataQuery } from '../../store/slices/apiSlice/map-api-slice';
import {
    useGetPropertyQuery,
    useGetSaleCompsQuery,
    useGetAcquisitionCompsQuery,
    useUpdateScoutPropertyMutation,
} from '../../store/slices/apiSlice/scout-api-slice';

import Loader from '../loader/loader';
import Page from '../page';
import { toastSuccess } from '../../utilities/toast';
import ScoutHeader from '../scoutHeader';
import {
    CompType,
    CompTypeKey,
    calculateInitialWeight,
} from '../../utilities/compsHelpers';
import { handleBackendError } from '../../utilities/errorHandling';
import ScoutTabs from './scoutTabs';

function getOrderedComps(comps, prop, compType) {
    const compRevisions =
    prop.PropertyScoutFields?.generic_data[CompTypeKey[compType]]?.comps || [];
    const compRemovals =
    prop.PropertyScoutFields?.generic_data[CompTypeKey[compType]]?.removedIds ||
    [];
    const workComps = [...comps];
    const orderedComp = [];
    let weightValue;

    for (const comp of compRevisions) {
        const id = comp.propertyId;
        const compIndex = workComps.findIndex((c) => c.pid === id);
        if (compIndex >= 0) {
            weightValue =
        comp.weight != null
            ? comp.weight
            : workComps[compIndex].sale_price > 0
                ? calculateInitialWeight(workComps[compIndex], prop, compType)
                : null;
            orderedComp.push({ ...workComps[compIndex], weight: weightValue });
            workComps.splice(compIndex, 1);
        } else {
            console.debug('Ordered comps contain props not being returned in comps');
        }
    }
    for (const comp of workComps) {
        orderedComp.push({
            ...comp,
            weight:
        comp.sale_price > 0
            ? calculateInitialWeight(comp, prop, compType)
            : null,
        });
    }

    const revisedComps = orderedComp.filter(
        (comp) => !compRemovals.includes(comp.pid)
    );
    return revisedComps;
}

function Scout() {
    let params = useParams();
    const {
        data: propertyData,
        error: propertyError,
        isLoading: propertyIsLoading,
        refetch: refetchProperty,
    } = useGetPropertyQuery(params.id);
    const { isLoading: mapInitIsLoading } = useGetMapInitDataQuery();
    const saleCompsResponse = useGetSaleCompsQuery(params.id);
    const acquisitionCompsResponse = useGetAcquisitionCompsQuery(params.id);

    const [updateScoutProperty, updateScoutPropertyResult] =
    useUpdateScoutPropertyMutation();

    const scoutProperty = useMemo(() => {
        const acqComps =
      propertyData && acquisitionCompsResponse.data?.comps
          ? getOrderedComps(
              acquisitionCompsResponse.data?.comps,
              propertyData,
              CompType.Acquisition
          )
          : [];
        const saleComps =
      propertyData && saleCompsResponse.data?.comps
          ? getOrderedComps(
              saleCompsResponse.data?.comps,
              propertyData,
              CompType.Sales
          )
          : [];
        return {
            propertyDetails: propertyData,
            acquisitionComps: {
                isLoading: acquisitionCompsResponse.isLoading,
                error: acquisitionCompsResponse.error,
                comps: acqComps,
            },
            saleComps: {
                isLoading: saleCompsResponse.isLoading,
                error: saleCompsResponse.error,
                comps: saleComps,
            },
        };
    }, [propertyData, acquisitionCompsResponse, saleCompsResponse]);

    useEffect(() => {
        const { isError, status, isSuccess } = updateScoutPropertyResult;
        if (isError) {
            handleBackendError('Update Scout Property', updateScoutPropertyResult);
        } else if (status === 'fulfilled' && isSuccess) {
            toastSuccess('Property Updated');
            refetchProperty(params.id);
        }
    }, [updateScoutPropertyResult]);

    const renderError = () => {
        console.error('Error retrieving property', propertyError);
        return <p>Error retrieving property information</p>;
    };

    const scoutPropertyUpdate = (fieldName, value) => {
        updateScoutProperty({
            propertyId: params.id,
            fieldName,
            value,
        });
    };

    return (
        <Page>
            <div className={propertyIsLoading ? 'opacity-50' : 'opacity-100'}>
                {propertyIsLoading ||
        mapInitIsLoading ||
        !scoutProperty.propertyDetails ? (
                        <Loader />
                    ) : propertyError ? (
                        renderError()
                    ) : (
                        <div className="container-fluid pb-3 ps-0 ps-md-4 pe-2">
                            <div className="row">
                                <div className="col-12">
                                    <ScoutHeader propertyDetails={scoutProperty.propertyDetails} />
                                </div>
                            </div>
                            <div className="col-12">
                                <ScoutTabs
                                    property={scoutProperty}
                                    onScoutPropertyUpdate={scoutPropertyUpdate}
                                />
                            </div>
                        </div>
                    )}
            </div>
        </Page>
    );
}

export default Scout;
